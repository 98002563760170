// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { get } from 'lodash';

// Utils
import ComponentMap from '../utils/component-map';
import SeoUtils from '../utils/seo-utils';

// Components
import Layout from '../components/layout';

import '../styles/pages/state-agents.scss';

const OklahomaAgents = ({ data }) => {
    const siteTitle = get(
        data,
        'allDatoCmsOklahomaaAgentsPage.edges[0].node.oklahomaAgentsPageTitle'
    );
    const queryData = get(
        data,
        'allDatoCmsOklahomaAgentsPage.edges[0].node.oklahomaAgentsPage'
    );
    const statesData = get(data, 'allDatoCmsState');

    const pageContext = {
        slug: 'oklahoma',
        carrierData: get(data, 'allDatoCmsOklahomaAgentsPage.edges[0].node'),
    };

    // Seo
    const seoTags = get(
        data,
        'allDatoCmsOklahomaAgentsPage.edges[0].node.seoMetaTags.tags'
    );
    const seoProps = {
        description: SeoUtils.extractProperty(seoTags, 'name', 'description'),
        twitterCardType: SeoUtils.extractProperty(seoTags, 'name', 'twitter:card'),
        cardImage: SeoUtils.extractProperty(seoTags, 'property', 'og:image'),
    };

    // Render components based on module
    const RenderComponents = queryData
        ? queryData.map((eacModule) => {
            const label = get(eacModule, 'model.apiKey');
            const isHero = label === 'hero';
            const isFeatureWithCtaQuote = label === 'features_with_cta_quote';
        const isFeatureWithImage2 = label === 'features_with_image2';
        const ComponentProps = {
                data: eacModule,
                ...(isHero && {
                    states: statesData,
                    pageData: pageContext,
                    showStateAgentsButtons: true,
                    showCarrierFilter: true,
                }),
                ...(isFeatureWithCtaQuote && {
                    isStatePage: true
                }),
          ...(isFeatureWithImage2 && {
            list: pageContext.carrierData.categoryFilter
          })
            };
            const eachComponent = ComponentMap(label, ComponentProps, eacModule.id);
            return eachComponent;
        })
        : [];

    return (
        <div>
            <Layout title={siteTitle} seoProps={seoProps}>
                {RenderComponents}
            </Layout>
        </div>
    );
};

OklahomaAgents.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default OklahomaAgents;

export const pageQuery = graphql`
    query oklahomaQuery {
        allDatoCmsState {
            edges {
                node {
                    id
                    stateTitle
                    stateSlug
                }
            }
        }
        allDatoCmsOklahomaAgentsPage {
            edges {
                node {
                    seoMetaTags {
                        tags
                    }
                    id
                    oklahomaAgentsPageTitle
                    categoryFilter {
                        carriers {
                            carrierTitle
                            carrierLogo {
                                url
                            }
                        }
                        lineFilter {
                            carrierLineCategoryTitle
                            originalId
                            selectCarrierCategory {
                                originalId
                                carrierCategoryName
                            }
                        }
                    }
                    oklahomaAgentsPage {
                        ... on DatoCmsHero {
                            id
                            title
                            description
                            heroDesktopImage {
                                url
                                gatsbyImageData(placeholder: BLURRED)
                            }
                            heroMobileImage {
                                url
                                gatsbyImageData(placeholder: BLURRED)
                            }
                            contentColor {
                                hex
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsIntroWithCta {
                            id
                            intro
                            introCtaText
                            introCtaLink
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsContact {
                            id
                            contactList {
                                contactPromoList {
                                    titleAndDescription
                                    callUsTitle
                                    contactUsNumber
                                    contactEmailField
                                    contactEmail
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsLinkPromo {
                            id
                            linkOption {
                                linkPromo {
                                    id
                                    title
                                    description
                                    link
                                }
                            }
                            model {
                                apiKey
                            }
                            backgroundColor {
                                hex
                            }
                        }
                        ... on DatoCmsStatsContent {
                            id
                            statsItem {
                                statsName
                                statDescription
                                statsItems {
                                    id
                                    statsInfo
                                    statsDescription
                                    statsColor {
                                        hex
                                    }
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsWebinarArticleList {
                            id
                            title
                            seeAllCtaLabel
                            selectArticle {
                                id
                                title
                                subtitle
                                slug
                                views
                                originalId
                                meta {
                                    publishedAt(formatString: "DD MMMM YYYY")
                                    updatedAt
                                }
                                featuredImage {
                                    url
                                }
                                model {
                                    apiKey
                                }
                                selectArticleTagCategory {
                                    articleTagTitle
                                    articleTagSubtitle
                                    slug
                                }
                            }
                            model {
                                apiKey
                            }
                            backgroundColor {
                                hex
                            }
                        }
                        ... on DatoCmsAgencyGuideArticleList {
                            id
                            title
                            seeAllCtaLabel
                            selectArticle {
                                id
                                title
                                subtitle
                                slug
                                views
                                originalId
                                meta {
                                    publishedAt(formatString: "DD MMMM YYYY")
                                    updatedAt
                                }
                                featuredImage {
                                    url
                                }
                                model {
                                    apiKey
                                }
                                selectArticleTagCategory {
                                    articleTagTitle
                                    articleTagSubtitle
                                    slug
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsArticleList {
                            id
                            title
                            seeAllCtaLabel
                            selectArticle {
                                id
                                title
                                subtitle
                                slug
                                views
                                originalId
                                meta {
                                    publishedAt(formatString: "DD MMMM YYYY")
                                    updatedAt
                                }
                                featuredImage {
                                    url
                                }
                                model {
                                    apiKey
                                }
                                selectArticleTagCategory {
                                    articleTagTitle
                                    articleTagSubtitle
                                    slug
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsBlogArticleList {
                            id
                            title
                            seeAllCtaLabel
                            selectArticle {
                                id
                                title
                                subtitle
                                slug
                                views
                                originalId
                                meta {
                                    publishedAt(formatString: "DD MMMM YYYY")
                                    updatedAt
                                }
                                featuredImage {
                                    url
                                }
                                model {
                                    apiKey
                                }
                                selectArticleTagCategory {
                                    articleTagTitle
                                    articleTagSubtitle
                                    slug
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsOnDemandArticleList {
                            id
                            title
                            seeAllCtaLabel
                            selectArticle {
                                id
                                title
                                subtitle
                                slug
                                views
                                originalId
                                meta {
                                    publishedAt(formatString: "DD MMMM YYYY")
                                    updatedAt
                                }
                                featuredImage {
                                    url
                                }
                                model {
                                    apiKey
                                }
                                selectArticleTagCategory {
                                    articleTagTitle
                                    articleTagSubtitle
                                    slug
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsPressReleaseArticleList {
                            id
                            title
                            seeAllCtaLabel
                            selectArticle {
                                id
                                title
                                subtitle
                                slug
                                views
                                originalId
                                meta {
                                    publishedAt(formatString: "DD MMMM YYYY")
                                    updatedAt
                                }
                                featuredImage {
                                    url
                                }
                                model {
                                    apiKey
                                }
                                selectArticleTagCategory {
                                    articleTagTitle
                                    articleTagSubtitle
                                    slug
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsSignPostImageList {
                            id
                            signPostImageList {
                                id
                                signTitle
                                signSubTitle
                                signDecription
                                signCtaText
                                signCtaLink
                                signImage {
                                    url
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsPartner {
                            id
                            title
                            subTitle
                            ctaText
                            ctaLink
                            carriersLogo {
                                id
                                carrierLogo {
                                    url
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsSignPostDefaultList {
                            id
                            signPostDefaultList {
                                id
                                heading
                                ctaText
                                ctaLink
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsFaqAccordion {
                            id
                            title
                            faqList {
                                faqList {
                                    id
                                    title
                                    body
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsFeaturesWithCta {
                            id
                            title
                            subtitle
                            featuresItem {
                                id
                                featuresList {
                                    id
                                    featureIcon {
                                        url
                                    }
                                    featureTitle
                                    featureDescription
                                    featureCtaText
                                    featureCtaLink
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsFeaturesWithCtaQuote {
                            id
                            title
                            subtitle
                            featuresItem {
                                id
                                featuresList {
                                    id
                                    featureIcon {
                                        url
                                    }
                                    featureTitle
                                    featureDescription
                                    featureCtaText
                                    featureCtaLink
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsFeaturesWithCta2 {
                            id
                            title
                            subtitle
                            featuresItem {
                                id
                                featuresList {
                                    id
                                    featureIcon {
                                        url
                                    }
                                    featureTitle
                                    featureDescription
                                    featureCtaText
                                    featureCtaLink
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsFeaturesWithImage {
                            id
                            title
                            featureBackgroundImage {
                                url
                            }
                            featureItemLink {
                                featuresItem {
                                    id
                                    featureItemTitle
                                    featureItemDescription
                                    itemIcon {
                                        url
                                    }
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsIntro {
                            id
                            introList {
                                stateIntroName
                                introHeading
                                introDescription {
                                    id
                                    description
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsPlanCard {
                            id
                            planLink {
                                planTitle
                                planDescription
                                planIcon {
                                    alt
                                    url
                                }
                                personalLineTitle
                                rateTitleForModel
                                personalRate {
                                    personalRateValue
                                    personalRateCategory
                                }
                                commercialLinesTitle
                                commercialLine {
                                    commercialRate
                                    commercialRateCategory
                                }
                                featureTitleForModal
                                featuresList {
                                    featureAvailable
                                    featureTitle
                                    featureValue
                                }
                                planPrice {
                                    planPrice
                                    planDuration
                                }
                                planCtaText
                                planCtaLink
                                planDetailCtaText
                                modalPlanDetailCtaLink
                                modalPlanDetailCtaText
                                modalContactUsCtaText
                                modalContactUsCtaLink
                            }
                            planHeaderTitle
                            planHeaderDescription
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsSignPostCenteredList {
                            id
                            signPostCenteredList {
                                id
                                heading
                                description
                                ctaText
                                ctaLink
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsSignPostSalesList {
                            id
                            backgroundColor {
                                hex
                            }
                            signPostSalesList {
                                id
                                salesTitle
                                salesDescription
                                readyToTalkCtaText
                                readyToTalkCtaLink
                                getInTouchTitle
                                getInTouchDescription
                                aboutUsTitle
                                signUpDescription
                                aboutUsCtaText
                                aboutUsLink
                                callUsTitle
                                callUsNumber
                                sendUsMessage
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsStatePromo {
                            id
                            statePromoTitle
                            statePromoList {
                                id
                                stateTitle
                                stateSlug
                                categoryFilter {
                                    carriers {
                                        carrierTitle
                                        carrierLogo {
                                            url
                                        }
                                    }
                                    lineFilter {
                                        carrierLineCategoryTitle
                                        originalId
                                        selectCarrierCategory {
                                            originalId
                                            carrierCategoryName
                                        }
                                    }
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsRichText {
                            id
                            addContent
                            meta {
                                createdAt
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsFeaturedArticle {
                            id
                            featuredArticle {
                                ... on DatoCmsArticle {
                                    id
                                    title
                                    subtitle
                                    slug
                                    views
                                    originalId
                                    featuredImage {
                                        url
                                    }
                                    selectArticleTagCategory {
                                        articleTagTitle
                                        slug
                                    }
                                    meta {
                                        createdAt(formatString: "DD MMM YYYY")
                                    }
                                }
                                ... on DatoCmsBlog {
                                    id
                                    title
                                    subtitle
                                    slug
                                    views
                                    originalId
                                    featuredImage {
                                        url
                                    }
                                    selectArticleTagCategory {
                                        articleTagTitle
                                        slug
                                    }
                                    meta {
                                        createdAt(formatString: "DD MMM YYYY")
                                    }
                                }
                                ... on DatoCmsWebinar {
                                    id
                                    title
                                    subtitle
                                    slug
                                    views
                                    originalId
                                    featuredImage {
                                        url
                                    }
                                    selectArticleTagCategory {
                                        articleTagTitle
                                        slug
                                    }
                                    meta {
                                        createdAt(formatString: "DD MMM YYYY")
                                    }
                                }
                                ... on DatoCmsPressRelease {
                                    id
                                    title
                                    subtitle
                                    slug
                                    views
                                    originalId
                                    featuredImage {
                                        url
                                    }
                                    selectArticleTagCategory {
                                        articleTagTitle
                                        slug
                                    }
                                    meta {
                                        createdAt(formatString: "DD MMM YYYY")
                                    }
                                }
                                ... on DatoCmsOnDemandTraining {
                                    id
                                    title
                                    subtitle
                                    slug
                                    views
                                    originalId
                                    featuredImage {
                                        url
                                    }
                                    selectArticleTagCategory {
                                        articleTagTitle
                                        slug
                                    }
                                    meta {
                                        createdAt(formatString: "DD MMM YYYY")
                                    }
                                }
                                ... on DatoCmsAgencyGuide {
                                    id
                                    title
                                    subtitle
                                    slug
                                    views
                                    originalId
                                    featuredImage {
                                        url
                                    }
                                    selectArticleTagCategory {
                                        articleTagTitle
                                        slug
                                    }
                                    meta {
                                        createdAt(formatString: "DD MMM YYYY")
                                    }
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsFeaturesWithImage2 {
                            id
                            title
                            description
                            featureDesktopImage {
                                url
                            }
                            contentColor {
                                hex
                            }
                            model {
                                apiKey
                            }
                        }
                        ... on DatoCmsLeadership {
                            id
                            title
                            subtitle
                            leadershipList {
                                id
                                profileImage {
                                    url
                                }
                                profileName
                                profileRoles
                                roleDescription
                                socialLink {
                                    id
                                    socialLinkText
                                    socialLink
                                }
                            }
                            model {
                                apiKey
                            }
                        }
                    }
                }
            }
        }
    }
`;
